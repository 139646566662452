$background-color: $color-black;

.pageContentWrapper__jet {
	background-color: $color-double-spanish-white;
	background: url("https://cdn.ontourmedia.io/gunsnroses/flygnair/splash/background_fly_woman.jpg");
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;

	@include break(md) {
    justify-content: center;
    background: url("https://cdn.ontourmedia.io/gunsnroses/flygnair/splash/background_fly.jpg");
    background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	}
  .contentContainer--sectionContent {
    width: 80vw;
    margin: 0 auto;
    align-items: flex-end;
    display: none;
    padding-top: 20px;
    padding-bottom: 20px;

    @include break(md) {
      width: 90vw;
      display: inherit;
      .sectionPanel--desktop {
        width: 40%;
        .sectionPanel--header  {
          color: $color-white;
          font-size: 58px;
          font-family: $font-primary_6;
          font-weight: 700;
          line-height: 62px;
          margin-bottom: 42px;
          @include break(md) {
            font-size: 42px;
            line-height: 48px;
          }
          @include break(xl) {
            font-size: 58px;
            line-height: 62px;
          }
        }
        .sectionPanel--content  {
          color: $color-white;
          font-size: 16px;
          font-family: $font-primary_6;
          font-weight: 300;
          line-height: 22px;
        }
      }
    }
    @include break(lg) {
      width: 80vw;
      .sectionPanel--desktop {
        width: 40%;
        .sectionPanel--header  {
          color: $color-white;
          font-size: 58px;
          font-family: $font-primary_6;
          font-weight: 700;
          line-height: 62px;
          margin-bottom: 42px;
          @include break(md) {
            font-size: 42px;
            line-height: 48px;
          }
          @include break(xl) {
            font-size: 58px;
            line-height: 62px;
          }
        }
        .sectionPanel--content  {
          color: $color-white;
          font-size: 21px;
          font-family: $font-primary_6;
          font-weight: 300;
          line-height: 28px;
        }
      }
    }
  }
}

.pageContentWrapper__shop {
	background: $color-red;
	background: url("https://cdn.ontourmedia.io/gunsnroses/flygnair/splash/background_shop.jpg");
	background-position: center center;
	background-size: cover;
  justify-content: center;
	@include break(md) {
    justify-content: center;
	}
  .contentContainer--sectionContent {
    width: 100%;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    align-items: flex-end;
      padding-top: 50px;
      padding-bottom: 50px;
      @include break(md) {
        padding-top: 0px;
        padding-bottom: 0px;
        width: 90vw;
      }
      @include break(lg) {
        width: 80vw;
      }

    .sectionPanel--desktop {
      width: 100%;
      display: flex;
      flex-direction: column;
      display: inherit;
        .sectionPanel--content {
          color: $color-white;
          text-align: center;
          padding-top: 30px;
          font-size: 14px;
          @include break(md) {
            display: none;
          }
        }
        .sectionPanel--button  {
          color: $color-white;
          font-size: 31px;
          font-family: $font-primary_6;
          font-weight: 400;
          text-transform: uppercase;
          margin-top: 13px;
          margin-bottom: 13px;
          border: 4px solid $color-white;
          padding-top: 5px;
          padding-bottom: 5px;
          display: flex;
          justify-content: center;
          &:hover {
              background-color: white;
              color: $color-red;
          }
        }
      @include break(md) {
        display: inherit;
        width: 40%;
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 20px;

        .sectionPanel--button  {
          color: $color-white;
          font-size: 18px;
          font-family: $font-primary_6;
          font-weight: 400;
          text-transform: uppercase;
          margin-top: 4px;
          margin-bottom: 4px;
          border: 4px solid $color-white;
          padding-top: 5px;
          padding-bottom: 5px;
          display: flex;
          justify-content: center;
          &:hover {
              background-color: white;
              color: $color-red;
          }
        }
      }
      @include break(lg) {
        display: inherit;
        width: 40%;
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 20px;

        .sectionPanel--button  {
          color: $color-white;
          font-size: 24px;

          padding-top: 5px;
          padding-bottom: 5px;


        }
      }
      @include break(xl) {
        display: inherit;
        width: 40%;
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 20px;

        .sectionPanel--button  {
          color: $color-white;
          font-size: 31px;

          padding-top: 5px;
          padding-bottom: 5px;


        }
      }
    }
  }
}

.pageContentWrapper__cta {
	background-color: $color-black;
  justify-content: center;
	@include break(md) {
		min-height: 135px;
    justify-content: center;
	}
  .contentContainer--sectionContent {
    width: 100%;
    margin: 0 auto;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    @include break(md) {
      width: 90vw;
      margin: 0 auto;
      align-items: flex-end;
      padding: 0px;
    }
    @include break(lg) {
        width: 80vw;
      }
    .sectionPanel--desktop {
      width: 40%;
      display: flex;
      justify-content: flex-end;
      display: none;
      @include break(md) {
        display: inherit;
        .sectionPanel--content  {
          color: $color-white;
          font-size: 15px;
          font-family: $font-primary_6;
          font-weight: 300;
          line-height: 18px;
        }
      }
    }
    .sectionPanel--mobile {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      display: inherit;
      flex-direction: column;
      .sectionPanel--header  {
          color: $color-white;
          font-size: 32px;
          font-family: $font-primary_6;
          font-weight: 700;
          line-height: 36px;
          margin-bottom: 22px;
        }
        .sectionPanel--content  {
          color: $color-white;
          font-size: 21px;
          font-family: $font-primary_6;
          font-weight: 300;
          line-height: 28px;
        }
      @include break(md) {
        display: none;
      }
    }
  }
}

.mainContentUser {
	color: #000000;
	font-family: 'tahoma';
	font-size: 48px;
	display: flex;
	justify-content: center;
}

@media (min-width: 54.875rem) {
	.mainContentUser {
			margin-top: 40px;
	}
}

@media (min-width: 54.875rem) {
		.mainContentUser {
			color: #000000;
			font-family: 'tahoma';
			font-size: 72px;
			display: flex;
			justify-content: center;
		}
}

.mainContentLink {
	color: #000000;
	font-family: 'tahoma';
	font-size: 40px;
	display: flex;
	justify-content: center;
	border-bottom: 0px;
	display: flex;
	max-width: 432px;
	margin: 0 auto;
}


	.mainContentLink img {
		width: 216px;
		height: 205px;
	}

@media (min-width: 54.875rem) {
	.mainContentLink img {
		width: 432px;
		height: 410px;
	}
}

.mainContentOverlay {
	visibility: hidden;
	display: inline-block;
	font-size: 20px;
	color: #ffffff;
	background: red;
	margin: 0 auto;
	padding: 10px 0px;
	position: relative;
	text-transform: uppercase;
	top: -110px;
	font-size: 1.562rem;
	font-family: 'librefranklin';
	font-weight: 700;
}

@media (min-width: 54.875rem) {
		.mainContentOverlay {
				top: -198px;
				font-size: 32px;
		}
}

.userContentLink:hover .mainContentOverlay{
	visibility: visible;
}


.userContent {
  font-weight: 300;
  letter-spacing: initial;
  line-height: 1.2rem;
  font-size: 1rem;
  margin: 20px;
	text-align: center;
	}
  @media (min-width: 54.875rem) {
    .userContent {
      margin: 0; } }
  .userContent p {
    margin-top: 15px;
    margin-bottom: 15px; }


