.instaWidget{

  margin:250px auto 10px;
  margin-top: 200px;
  display: block;
  //justify-content: space-around;
  //justify-content: center;
  //flex-grow: 0;
  //border:2px solid #f3c534;
  //border-radius: 20px;
  padding:20px;
  background-color: #FFFFFF;
  //flex-direction: row;
  //
  //align-items: flex-start;

  //justify-content: space-around;
  //flex-flow: row wrap;
  //flex-direction: row;
  //flex-wrap: wrap;
  max-width: 500px;
  //align-items: stretch;
  h2{
    width:100%;
    text-align: center;
    font-size: 22px;
    color: #000000;
    letter-spacing: 0;
    margin: 5px 0;
    background-color: #ffffff;
  }
  .instaWidget_image{
    width: 100%;
    height: auto;
    background-color: white;
    margin-bottom: 40px;

    //flex:1 1 auto;
    //align-self:flex-start;

    .instaWidget_title{
      padding: 10px;
      font-weight: 700;
      display: flex;
      align-items: center;
      font-size: 14px;
      @include break(md){
        padding: 15px;
        font-size: 20px;
      }
      img{
        width:40px;
        height: auto;
      }
    }
    .instaWidget_detail{
      padding: 10px;
      font-size: 14px;
      line-height: 1.2em;
      margin-top: -10px;
      .instaWidget_tag{
        font-weight: 700;
      }

      @include break(md){
        padding: 20px;
        font-size: 18px;
      }
    }
    img{
      width: 100%;
      height: auto;
    }
  }
  @include break(xs){
    width: 80%;
  }
}
