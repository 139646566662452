// html, body { }
.l-pageContainer {
  // background-color: yellow;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  @include break(md) {
    flex-direction: row;
    height: 100vh;
    align-content: auto;
  }
  @include break(xl) {
    height: 100vh;
    //height: 100%;
    align-content: auto;
  }
  .l-pageHeader {
    //flex-grow: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
    //position: fixed;
    //top: 0px;
    width: 100%;
    //flex: 0 0 0;
    @include break(md) {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
     // height: 132px;
      //height: 1px;
      height: 132px;
      //background: green;

    }
    .l-navContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
      height: 70px;
      //background-color: $color-white;
      z-index: 200;
      @include break(md) {
        display: none;
        align-items: flex-end;
      }
    }
    .l-logoContainer {
				display: block;
				//position: absolute;
				text-align: center;
				width: 100%;
				top: 28px;
				img {
					height: 85px;
				}
        @include break(md) {
          padding-top: 25px;
					img {
						height: 210px;
					}
        }

      }
    .l-menuContainerSocial {
      display: none;
      @include break(md) {
        width: 25%;
        flex-grow: 1;
      }
    }
		.l-menuWrapperRight {

      @include break(md) {
        width: 25%;
        flex-grow: 1;
      }
		}
    .l-menuWrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      z-index: 100;
      margin: -50px auto;
      width: 100%;
      //width: 85px;
      @include break(md) {
        flex-direction: row;
        height: 132px;
        width: 90vw;
        margin: 0 auto;
        display: block;
        position: relative;

      }
      @include break(lg) {
        width: 80vw;
      }
      .l-menuContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        height: calc(100vh - 56px);
        background-color: $color-white;;
        justify-content: flex-start;
        @include break(md) {
          height: inherit;
          background-color: inherit;
          display: block;
        }
        margin: 0;
      }
      .l-menuContainer.js-l-mobile {
        display: none;
        @include break(md) {
          display: flex; //height: 100px;
          justify-content: center;

          position: absolute;
          top: 0px;
          right: 0px;
        }
        @include break(lg) {
          display: flex; //height: 100px;
          justify-content: center;

          position: absolute;
          top: 0px;
          right: 0px;
        }
      }
    }
  }
  .l-pageContentWrapper {
    // background-color: tomato;
    flex: 0;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    .l-contentContainer {
      flex: 0;
    }
    @include break(md) {
    }
  }

  .l-pageContentWrapper__jet {
    // background-color: tomato;

    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 50vh;
    .l-contentContainer {
      //flex: 0;
    }
    @include break(xs) {
    	//height: 40vh;
    }
    @include break(sm) {
    	//height: 80vh;
    }
    @include break(bsm) {
    	height: 80vh;
    }
    @include break(md) {
    	height: 50%;
    }
    @include break(lg) {
    	height: 55%;
    }
    @include break(xx) {
    	height: 65%;
    }
     @include break(xl) {
    	height: 65%;
    }
    @include break(xxl) {
    	height: 50%;
    }
  }
  .l-pageContentWrapper__shop {
    // background-color: tomato;

    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    order: 4;
    .l-contentContainer {
      //flex: 0;
    }
    @include break(md) {
    	order: 3;
      height: 35%;
      flex: 1 1 auto;
    }
  }
  .l-pageContentWrapper__cta {
    // background-color: tomato;

    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    order: 3;
    .l-contentContainer {
      //flex: 0;
    }
    @include break(md) {
    	order: 4;
    	flex: 1 1 auto;
      height: 132px;
      align-self: flex-end;
    }
  }
  .l-pageFooter {
    //z-index: 1;
    background-color: $body-background;
    .l-pageFooter--container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    width: 100%;
    @include break(md) {
      width: 100%;
      .l-pageFooter--container {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;

      }
    }
  }
}

.l-pageContentWrapper__flair {
				display: block;
				position: absolute;
				width: 90%;
				left: 0px;
        top: 110px;
        height: 100%;
        display: none;
				img {
          width: auto;
          position: absolute;
          //top: 20%;
          max-height: 306px;

				}
        @include break(xs) {
          width: 80%;
          img {
            //top: 12%;
          }
        }
        @include break(sm) {
          width: 70%;
          height: inherit;
          padding-top: 40px;
          img {
            //top: 10%;
          }
        }
        @include break(bsm) {
          width: 50%;
          img {
            //top: 70px;
          }
        }
        @include break(md) {
          display: block;
          position: absolute;
          width: 55%;
          left: 0px;
          height: 100%;
          top: 0px;
					img {
            max-width: 100%;
            width: auto;
            position: absolute;
            top: 10%;
            max-height: 80%;
          }
        }

   }

.l-contentHeaderContainer {
  .l-header {
    min-height: 50px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 30px 2px;
    @include break(md) {
      padding: 20px;
    }
    .l-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      span.title {
        letter-spacing: 8px;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        font-family: "Montserrat", Arial, Tahoma, sans-serif;
        color: #009cde;
      }
    }
    .l-breadCrumbs {
      display: none;
      @include break(md) {
        display: flex;
      }
      flex-wrap: nowrap;
      justify-content: flex-end;
      li {
        margin-left: 10px;
        color: #009cde;
        a {
          cursor: auto;
          font-size: 1.1rem;
          letter-spacing: 2px;
        }
        a, a:visited, a:focus {
          text-decoration: none;
          outline: 0;
        }
      }
    }
  }
}