.lightboxContainer {
  width:100%;
  height:100%;
  padding:10px;
  //box-shadow: 0px 0px 39px 12px rgba(0,0,0,0.75);
  //background-color:#fff;

  .lightboxContent {
    width: 100%;
    height: 100%;
    //background-color: #333333;
    display: -ms-flexbox;
    display: flex;
    //flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      width: auto;
      height: 100%;
    }

    .imageNavPanel {
      position: absolute;
      height: 90%;
      top: 0;
      width: 10%;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;

      &--left {
        left: 0;
        color: $color-red;
        .imageNavPanel--left--img {
        	height: 70px;
        	width: 34px;
        	background: url(https://cdn.ontourmedia.io/gunsnroses/site_v2/lightbox-left.png) center center no-repeat;
					background-size: 100%;
        }
        &:hover {
        	color: $color-white;
        }
      }

      &--right {
        right: 0;
        color: $color-red;
        .imageNavPanel--right--img {
        	height: 70px;
        	width: 34px;
        	background: url(https://cdn.ontourmedia.io/gunsnroses/site_v2/lightbox-right.png) center center no-repeat;
					background-size: 100%;
        }
        &:hover {
        	color: $color-white;
        }
      }
    }

    .imageTitle {
      left: 20px;
      bottom: 0;
      width: 100%;
      text-align: left;
      display: none;
    }
  }
}

#miniLB__wrapper  {
	z-index: 99999;
	button {
		top: 20px;
		left: 20px;
	}
}

#miniLB__fixture {
	z-index: 99999;
}
