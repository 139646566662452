
.ssoContent{
  display:none;
  flex-direction: column;
  align-items: center;
  height: auto;
  //margin:20% auto 0;
  width: 90%;
  //border-top: 2px solid #f3c534;
  //border-bottom: 2px solid #f3c534;
  //border-radius: 20px;
  background-color: #FFFFFF;
  max-width: 400px;
  min-height: 300px;
  .ssoContent_Frame{
    width: 100%;
    height: 330px;
    @include break(md){
      //margin-top: 15px;
      width: 100%;
    }
  }
  .messageContent{
    padding:20px;
  }
}
