/*
body.cockpit{
  background-image:url("https://cdn.ontourmedia.io/gunsnroses/flygnair/images/plane/cockpit.jpg");
  background-repeat:no-repeat;
  background-size: cover;
  background-color: black;
  background-attachment: fixed;
  background-positino: top center;
  .logo{
    width:100%;
    height:auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    position:fixed;

    img{
      width: 100%;
      max-width: 150px;
    }

    @include break(md){
      align-items: flex-start;
      margin: 10px;
    }
  }

  .internalLink{
    &.internalLink--main{
      width:1px;
      height:1px;
      position:absolute;
      top:44%;
      left:44%;
      //border:2px solid red;
    }
  }


}
*/
#cockpit.cockpit{
  background-image: url("https://cdn.ontourmedia.io/gunsnroses/flygnair/images/plane/cockpit_gnr_mb.jpg");

  @include break(md) {
    background-image: url("https://cdn.ontourmedia.io/gunsnroses/flygnair/images/plane/cockpit_gnr.jpg");
  }

  background-position: top center;
  background-position-y: 132px;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.l-pageContentWrapper {
  &.l-pageContentWrapper__cockpit {

    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    .l-contentContainer {
      flex: 0;
    }


    width: 100%;

  }
}
