$menuitem-font-color: $color-white;
$menuitem-border-color: $color-black;
$search-background-color: $color-gold;
$toggle-menu-font-color: $color-white;
$search-button-font-color: $toggle-menu-font-color;
$search-white-square-font-color: $color-white;
$close-button-font-color: $menuitem-font-color;
$search-input-border-color: $search-white-square-font-color;
$header-font-family: $font-primary_2, $font-secondary_2, $font-secondary_1;
$header-font-size: $site-font-size;

.pageContainer {
  .pageHeader {
    .navContainer {
    }
    .navContainerMobile {
    }

    .logoContainer {

    	.logoContainer__logo {

				@include break(md) {

				}
				&:hover {
					@include break(md) {

					}
				}
				&:focus {
					@include break(md) {

					}
				}
				&:active {
					@include break(md) {

					}
				}
			}
    	img {

      }

      @include break(md) {

      	a {

        }
        img {

        }

      }

    }
		.menuContainerSocial {
			@include break(md) {
        height: px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
      &__top {
      	display: none;
		  	margin: 0;
		  	padding: 0;
		  	height: 36px;
		  	padding-right: 20px;
		  	.menuContainerSocial__socialIcon {
		  		color: $color-white;
		  		font-size: 21px;
		  		height: 21px;
		  		display: flex;
		  		justify-content: center;
		  		align-items: center;
		  	}
		  }
		  &__socialsection {
		  	margin-right: 20px;
		  	margin-bottom: -10px;
		  }
		  &__managed {
		  	text-align: center;
		  	padding-bottom: 10px;
		  }
		  &__circle {
		  	width: 21px;
		  	height: 21px;
		  	padding: 0;
		  	color: $color-black;
		  	margin-left: 5px;
		  	&:hover {
		  		background-color: transparent;
		  		i {
		  			color: $color-gold;
		  		}
		  	}
		  }
		}
    .menuWrapper {

      @include break(md) {
        border: none;
      }

      .menuContainer {
        flex: 1;
        .menuList {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          margin: 0px;
          padding-right: 20px;
          padding-left: 20px;
          @include break(md) {
            flex-direction: row;
            height: 56px;
            align-items: center;
            justify-content: flex-end;
            background-color: transparent;
            //padding-right: 35px;
            padding-right: 0px;
            padding-left: 0px;
          }

          .menuItem {
            margin: 0px;
            padding: 0;
						display: flex;

						padding-right: 10px;
						padding-left: 10px;
						margin: 5px;
						//width: 100%;
						justify-content: center;
						&.menuItem--mobile  {
							display: inherit;
							@include break(md) {
								display: none;
							}
					 }
						@include break(md) {
              margin: inherit;
							padding-right: 0px;
							padding-left: 0px;
							height: inherit;
							background-color: inherit;
							align-items: center;
            }

            a {
              display: none;
              color: $color-black;
              font-size: $size-p5;
              font-family: $font-primary-6;
              font-weight: 300;
              text-transform: uppercase;

              &:hover {
              }
              &:active {
              }
              &:target {
              }
              @include break(md) {
                padding: 10px 2px;
						    margin: 0 10px;
						    text-transform: uppercase;
						    font-weight: 400;
						    font-size: 1rem;
						    letter-spacing: 1px;
						    color: $color-white;
								font-family: $font-primary_6;
                background-color: $color-red;
                padding-left: 25px;
                padding-right: 25px;
							  &:hover, &:active, &:focus {
							    outline: 0;
							    text-decoration: none;
							    color: $color-white;
							    background-color: $color-black;

							  }

              }

              text-transform: uppercase;
              font-weight: $strong-p1;
              border: none;
              text-align: center;
              transition: all 0.3s ease-in-out;
              span {
                padding: 0 0px;
								//display: block;
                @include break(md) {
                }
                white-space: nowrap;
              }
              @include break(md) {
	              &:hover {
	              	color: $color-white;
	              }
	              &:focus {
	              	color: $color-white;
              }
              }
            }

            a.currentSelection {
              color: $color-white;
              &:before {
							    	content: "[";
							    	color: $color-gold;
							    	width: 0px;
							    	display: inline-block;
							    	position: relative;
							    	bottom: 1px;
							    	left: -10px;
							    }
							    &:after {
							    	content: "]";
							    	color: $color-gold;
							    	width: 0px;
							    	display: inline-block;
							    	position: relative;
							    	right: -5px;
							    	bottom: 1px;
							    }
            }
            &--join a {
            	background-color: $color-gold;
            	padding-right: 15px;
            	padding-left: 15px;
            	color: $color-black;
            		&:before {
            			content: "\f234";
									font-family: FontAwesome;
									padding-right: 10px;
            		}
            		&:hover {
	            		background-color: $color-white;
	            		color: $color-black;
	            		&:before {
	            			color: $color-black;
	            		}
            	}
            }
            &--upgrade a {
					    border: 1px solid $color-gold;
					    border-radius: 15px;
					    padding: 5px 10px;
            	color: $color-gold;
            		&:before {
            		}
            		&:hover {
	            		background-color: $color-gold;
	            		color: $color-black;
	            		&:before {
	            		}
            	}
            }
            &--renew a {
            	background-color: $color-gold;
            	padding-right: 15px;
            	padding-left: 15px;
            	color: $color-black;
            		&:before {
            			content: "\f2c0";
									font-family: FontAwesome;
									padding-right: 10px;
            		}
            		&:hover {
	            		background-color: $color-white;
	            		color: $color-black;
	            		&:before {
	            			color: $color-black;
	            		}
            	}
            }
            &--signin a {
            	background-color: $color-eclipse;
            	padding-right: 15px;
            	padding-left: 15px;
            	color: $color-white;
            	&:before {
            			content: "\f084";
									font-family: FontAwesome;
									padding-right: 10px;
            		}
            		&:hover {
	            		background-color: $color-white;
	            		color: $color-black;
	            		&:before {
	            			color: $color-black;
	            		}
            	}
            }
            &--logout a {
            	background-color: $color-eclipse;
            	padding-right: 15px;
            	padding-left: 15px;
            	color: $color-white;
            	&:before {
            			content: "\f08b";
									font-family: FontAwesome;
									padding-right: 10px;
            		}
            		&:hover {
	            		background-color: $color-white;
	            		color: $color-black;
	            		&:before {
	            			color: $color-black;
	            		}
            	}
            }
          }
        }

        .menuListTop {
        	.menuItem.btn {
        			display: none;
        			@include break(md) {
		            display: inherit;
		          }
        	}
        }
        .menuListBottom {
          @include break(md) {
            display: none;
          }
          .menuItem--newsletter {
            display: none;
          }
          .menuItem {
            margin-bottom: 5px;
          }
        }
        .menuListRight {
        	display: none;
        	@include break(md) {
        		display: flex;
        		padding-right: 20px;
        		justify-content: flex-end;
        	}
        	.menuItem.btn {
        			a {
        				font-family: $header-font-family;
        				font-size: 14px;
        				text-transform: capitalize;
        				letter-spacing: 0px;
        				line-height: 1.25;
        				padding: 5px 10px;
        				margin-right: 0px;
        				margin-left: 0px;
        				&:hover {
        					&:before {
        						content: "";
        					}
        					&:after {
        						content: "";
        					}
        				}
        				&:focus {
        					&:before {
        						content: "";
        					}
        					&:after {
        						content: "";
        					}
        				}
        			}
        	}
        }
      }

      .l-searchContainer {
        visibility: hidden;

        display: flex;

        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .l-searchWrapper {
        display: none;

        .l-searchWrapper__form {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      &.showSearchPanel {
        .l-searchContainer {
          display: none;
        }
      }

      &.showSearchPanel {
        .l-searchWrapper {
          display: block;
        }
      }

      .searchWrapper {
        background-color: $search-background-color;
      }

      .searchContainer {
        margin: 0;
      }
    }
  }
}

#home .navContainer {
background-color: $color-black;
		@include break(md) {
		    background-color: transparent;
		  }
    }

.toggleMenuContainer {
  position: absolute;
  right: 30px;
  top: 0px;
  @include break(md) {
    display: none;
  }

  a {
    border: none;
    font-size: 20px;
    color: $toggle-menu-font-color;
  }
}

.searchButton {
  font-size: $header-font-size * 1.125;
  text-decoration: none;
  border: none;

  line-height: 2.5rem;
  color: $search-button-font-color;

  &.searchButton--default {
    padding: 2px 30px;
  }

  &.searchButton--whiteSquare {
    padding: 1rem;
    color: $search-white-square-font-color;
  }
}

.closeButton {
  &.closeButton--dimmedSquare {
    padding: 1rem;
    color: $close-button-font-color;
    opacity: .75;
  }
}

.searchInput {
  flex: 1;

  margin: 0;
  box-shadow: 0 0 0;

  font-size: $header-font-size;
  padding-left: 5px;
  background: none;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: rgba($search-input-border-color,.08);
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          	.pageContainer .pageHeader .menuWrapper .menuContainer .menuList {
          		background-color: transparent;
          	}
          }


/* Sets body to not scroll when nav is open on mobile */
body.no-scroll{
  height: 100vh;
  width: 100vw;
  position: fixed;
}

#home .pageContainer {
  .pageHeader {
  	background-color: transparent;
  }
}

.swisyMobileMenu {
  // width: 60px;
  width: 38px; // height: 45px;
  height: 32px;
  position: relative;
  margin: 50px auto;
  margin: 20px auto;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute; // height: 6px;
    height: 3px;
    width: 50%;
    background: $color-black;
    opacity: 1;
    transform: rotate(0deg);
    transition: .45s ease-in-out;
  }
  span:nth-child(even) {
    left: 50%;
    border-radius: 0;
  }
  span:nth-child(odd) {
    left: 0px;
    border-radius: 0;
  }
  span:nth-child(1), span:nth-child(2) {
    top: 0px; // background: green;
  }
  span:nth-child(3), span:nth-child(4) {
    top: 13px; // top: 18px;
  }
  span:nth-child(5), span:nth-child(6) {
    top: 26px; // top: 36px;
  }
}
.js-swisyMobileMenu.open span:nth-child(1), .js-swisyMobileMenu.open span:nth-child(6) {
  transform: rotate(45deg);
}
.js-swisyMobileMenu.open span:nth-child(2), .js-swisyMobileMenu.open span:nth-child(5) {
  transform: rotate(-45deg);
}
.js-swisyMobileMenu.open span:nth-child(1) {
  left: 2px; // left: 5px;
  top: 7px;
}
.js-swisyMobileMenu.open span:nth-child(2) {
  left: calc(50% - 2px); // left: calc(50% - 5px);
  top: 7px;
}
.js-swisyMobileMenu.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}
.js-swisyMobileMenu.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}
.js-swisyMobileMenu.open span:nth-child(5) {
  left: 3px; // left: 5px;
  // top: 29px;
  top: 20px;
}
.js-swisyMobileMenu.open span:nth-child(6) {
  left: calc(50% - 3px); // left: calc(50% - 5px);
  top: 29px;
  top: 20px;
}
