//  Site Settings for MasterZF2
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Content Page
//   3. Breakpoints
//   4. Typography



// 1. Global
// ---------
// Use this site to look up color names
// http://www.color-blindness.com/color-name-hue/

$global-font-size: 14px;
$global-lineheight: 1.3;

$color-palette: (
  primary: #2199e8,
  secondary: #777,
  success: #3adb76,
  warning: #ffae00,
  alert: #ec5840,
);

$color-white: #fff;
$color-black: #000;
$color-grey: #7e7e7e;
$color-red: #e42827;
$color-grey-night-rider: rgba(255, 255, 255, .2);
$color-tiber: #e2e2e2;
$color-jewel: #d6b161;
$color-nero: #222;
$color-iris-blue: #0ca4e1;
$color-malibu: #57b9e5;
$color-gainsboro: #e8e8e8;
$color-trout: #475058;
$color-mine_shaft: #333333;
$color-wild_sand: #f4f4f4;
$color-storm_dust: #646464;
$color-masala: #3c3b3b;
$color-silver_chalice: #aaaaaa;
$color-puerto_rico: #2ebaae;
$color-desert_storm: #f8f8f8;
$color-white_smoke: #f4f4f4;
$color-dim_gray: #646464;
$color-light-gray: #e6e6e6;
$color-medium-gray: #cacaca;
$color-dark-gray: #8a8a8a;
$color-suva-gray: #909090;
$color-secondary: #777;
$color-crimson: #DC143C;
$color-nobel: #999;
$color-black_01: #111;
$color-gray_01: #7d7d7d;
$color-sunglow: #fac43d;
$color-fire_brick: #9c1c1c;
$color-light-gray_01: #d6d6d6;
$color-charcoal: #444;
$color-summer_sky: #2991d6;
$color-pacific_blue: #009cde;
$color-mariner: #39579d;
$color-gold: #fac43d;
$color-mariner: #39579d;
$color-silver: #bcbcbc;
$color-chetwode-blue: #6179b1;
$color-cerulean-blue: #029BDE;
$color-driftwood: #a89646;
$color-suva-grey: #939393;
$color-mortar: #555555;
$color-white-smoke: #eeeeee;
$color-eclipse: #3b3b3b;
$color-dove-grey: #646464;
$color-bright-sun: #fac43d;
$color-old-grey: #a1a1a1;
$color-dark-grey: #282828;
$color-double-spanish-white: #eae1c3;

$body-background: $color-white;
$body-background-inverse: $color-black;
$body-font-color: $color-black;
$body-hover-color: $color-red;
$body-heading-color: $color-white;
$global-margin: 1rem;
$global-padding: 1rem;
$global-weight-normal: 400;
$global-weight-bold: 700;
$global-radius: 0;
$global-flexbox: false;



//   2. Content Page
// --------------
$content-background: $color-white;
$content-font-color: $color-black;
$content-divider-color: $color-white;


// 3. Breakpoints
// To be used by @mixin break($size)
// --------------
$breakpoints: (
  xs: 16rem,   // 256px
  sm: 25.875rem,  // 414px
  bsm: 47.12rem,   //754px
  md: 56rem, 	// 896px
  lg: 64rem,   // 1024px - iPad Mini
  xl: 76.50rem,  // 1224px
  xx: 95rem,		// 1520px
  xxl: 130rem		// 2800px
);

// 4. Typography
// ---------------------
//@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,500,700');
//@import url('https://fonts.googleapis.com/css?family=Montserrat');
//@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700');
$font-primary: 'librefranklin-regular', Arial, sans-serif;
$font-primary_1: 'librefranklin-regular', Arial, sans-serif;
$font-primary_2: 'librefranklin-regular', Arial, sans-serif;
$font-primary_3: 'librefranklin-regular', Arial, sans-serif;
$font-primary_4: 'librefranklin-regular', Arial, sans-serif;
$font-primary_5: 'Roboto', Arial, sans-serif;
$font-primary_6: 'Roboto Condensed', Arial, sans-serif;


$font-secondary: Helvetica;
$font-secondary_1: sans-serif;
$font-secondary_2: serif;
$font-secondary_3: Arial;
$font-secondary_4: Tahoma;
$font-secondary_5: 'Montserrat', sans-serif;

$body-font-family: $font-primary, $font-primary_3, $font-secondary_1;

@font-face {
    font-family: 'opticorvinusskyline';
    src: url('https://cdn.ontourmedia.io/gunsnroses/site_v2/fonts/opticorvinus-skyline-webfont.woff2') format('woff2'),
         url('https://cdn.ontourmedia.io/gunsnroses/site_v2/fonts/opticorvinus-skyline-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'librefranklin-regular';
    src: url('https://cdn.ontourmedia.io/gunsnroses/site_v2/fonts/librefranklin-regular.woff2') format('woff2'),
         url('https://cdn.ontourmedia.io/gunsnroses/site_v2/fonts/librefranklin-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



// assume base size of 16
//10px
$size-m4: 0.625rem;

// 11px
$size-m3: 0.688rem;

// 12px
$size-m2: 0.75rem;

// 14px
$size-m1: 0.875rem;

// 16px
$size-base: 1rem;

// 18px
$size-p1: 1.125rem;

// 20px
$size-p2: 1.250rem;

// 22px
$size-p3: 1.375rem;

// 24px
$size-p4: 1.5rem;

// 25px
$size-p5: 1.562rem;

// 30px
$size-p6: 1.875rem;

// 33px
$size-p6a: 2.063rem;

// 36px
$size-p6b: 2.25rem;

// 40px
$size-p6b: 2.5rem;

// 45px
$size-p6c: 2.813rem;

// 50px
$size-p7: 3.125rem;

// 60px
$size-p8: 3.75rem;

$lead-font-size: $global-font-size * 1.25;
$site-font-size: $global-font-size;
$header-sizes: (
  'h1': $size-p4,
  'h2': $size-p3,
  'h3': $size-p2,
  'h4': $size-p1,
  'h5': $size-base,
  'h6': $size-m1
);

$light: 100;
$strong-base: 300;
$strong-p1: 400;
$strong-p1b: 500;
$strong-p2: 700;
$strong-p3: 900;


