
$offer-font-color: $color-white;
$offer-price-border-color: $offer-font-color;
$offer-feature-list-font-color: $offer-price-border-color;
$offer-feature-list-border-color: $offer-feature-list-font-color;
$offer-feature-font-color: $color-gray_01;
$offer-h2-font-color: $color-gold;
$offer-detail-background-color: $color-black;
$offer-detail-border-color: $offer-detail-background-color;
$offer-data-entry-background-color: $offer-detail-border-color;
$offer-data-entry-border-color: $offer-data-entry-background-color;
$offer-purchase-background-color: $offer-feature-list-border-color;
$offer-purchase-font-color: $offer-data-entry-border-color;
$offer-purchase-border-color: $color-gainsboro;
$thankyou-background-color: $offer-data-entry-border-color;
$thankyou-font-color: $offer-purchase-background-color;
$thankyou-border-color: $color-black;

$section-font-family: $font-primary, $font-secondary_1;
$section-font-size: $site-font-size;

.contentContainer--sectionContent {

  display: flex;
  flex-direction: column;

		
   .sectionWrapper {
     display: flex;
     flex-direction: column;
      @include break(md) {
        flex-direction: row;
      }
   }
        
  @include break(md) {
    .sectionPanel {
      flex: 1;
    }
  }
	width: 100%;
	background-color: transparent;
	font-family: $section-font-family;
    @include break(md) {
	}

	@include break(xl) {
	}

}


