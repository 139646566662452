.gmapPlayground{
  width:100%;
  height:100%;
  //position:relative;
  .canvas_image{
    width:100%;
    height:100%;
    min-height:100vh;
    display:none;
  }
  .gmap_plane{
    position:absolute;
    z-index:100;
    display:none;
    width:200px;
    img{
      width: 100%;
      height: auto;
    }
    &.gmap_plane--rtlt{
      display:inline;
      top:-100px;
      right:200px;
      transform: rotate(15deg);
    }
    &.gmap_plane--side{
      display:inline;
      width:650px;
      top:30%;
      left:-650px;
      max-width: 80%;
      @include break(md){
        top: 18%;
      }
    }
    &.gmap_plane--logo{
      width:100%;
      margin:10px auto 0;
      //text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      img{
        max-width: 150px;
      }
      @include break(md){
        width: 30px;
        max-width: 180px;
        text-align: left;
        margin-left: 10px;

      }
    }
  }
}
